import ko from 'knockout';

import './polyfill/array-from';
import './polyfill/element-closest';
import './lib/accordion';
import './lib/contact-map';
import './lib/contacts-worldwide';
import './lib/form';
import './lib/form-mwf';
import './lib/gallery-slider';
import './lib/gmaps-init';
import './lib/js-link';
import './lib/magazine-teasers';
import './lib/multi-teaser-slider';
import './lib/resize-body-class';
import './lib/search';
import './lib/sticky-header';
import './lib/toggle-navigation';
import './lib/toggle-visibilty';
import './lib/video-youtube-vimeo';
import './lib/video-iframe';
import './lib/clipboard';
import './lib/geojson-map';
import './lib/functions/lightbox';

window.ko = ko;
